html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html {
    font-size: 50px;
}

@media (max-width: 1200px) {
    html {
        font-size: 45px;
    }
}

@media (max-width: 860px) {
    html {
        font-size: 42px;
    }
}

@media (max-width: 500px) {
    html {
        font-size: 38px;
    }
}

body {
    background: #fff;
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.3;
}

* {
    box-sizing: border-box;
}

a {
    color: #fff;
    text-decoration: none;
}

.bold,
.strong,
b,
strong {
    font-weight: bold;
}

img {
    max-width: 100%;
    height: auto;
}

.App {
    margin: 0 auto;
    max-width: 540px;
    position: relative;
    min-height: 100vh;
    box-shadow: 0 0 0.13333rem 0 rgba(125, 124, 124, 0.3);
}

input:focus,
select:focus,
select:active {
    outline: .01333rem solid #13a2ba;
    -webkit-user-select: text;
    user-select: text;
}

.title {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 0.32rem;
    color: #f2f2f2;
    font-size: 0.4rem;
    font-weight: 600;
}

.title:before {
    content: "";
    display: block;
    width: 0.08rem;
    height: 0.4rem;
    background: #f2f2f2;
    margin-right: 0.13333rem;
}

.title-h1 {
    text-align: center;
    color: #fff;
    font-size: 0.7rem;
    font-weight: bold;
    position: relative;
    z-index: 1;
    margin: 0.6rem 0;
}

.login {
    padding: 0 0.32rem;
    min-height: 100vh;
    background: linear-gradient(hsla(0, 0%, 100%, 0), #0b88a1), url(./img/login-bg.png);
    display: flex;
}

.form-lg input {
    width: 90%;
    height: 1.17333rem;
    padding: 0.36rem 0.34667rem;
    margin: 0 0 0.2rem;
    font-size: 0.37333rem;
    border: none;
    border-radius: 0.26667rem;
    box-shadow: 0 0.05333rem 0.21333rem #d0d0ed5c;
}

.form-lg select {
    width: 90%;
    height: 1.17333rem;
    padding: 0.36rem 0.34667rem;
    margin: 0 0 0.2rem;
    font-size: 0.37333rem;
    border: none;
    border-radius: 0.26667rem;
    box-shadow: 0 0.05333rem 0.21333rem #d0d0ed5c;
}

.form-lg label {
    margin: 0.2rem auto 0.1rem;
    text-align: left;
    max-width: 90%;
    display: block;
}

.login h1 {
    margin: 0.3rem 0 0;
    text-transform: uppercase;
    color: #fff;
    font-size: 0.39rem;
    font-weight: bold;
}

.form-lg {
    margin: auto;
    width: 100%;
}

.form-lg .inputs {
    padding-bottom: 0.2rem;
}

.form-lg p:not(:empty) {
    color: #eee;
    font-size: 0.4rem;
    margin-bottom: 0.5rem;
}

.form-lg button {
    height: 1.06667rem;
    color: #fff;
    font-size: 0.48rem;
    font-weight: 700;
    letter-spacing: 0.05333rem;
    text-shadow: none;
    border-radius: 1.06667rem;
    border: none;
    background: linear-gradient(179deg, #13a2ba, #087c95);
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0.5rem auto;
}

.login form .input {
    position: relative;
}

.login form svg {
    font-size: 24px;
    color: #666;
    right: 35px;
    top: 54%;
    transform: translateY(-50%);
    position: absolute;
}

.main {
    background: #0c192c;
    color: #f2f2f2;
    padding: 0 0.32rem 2rem;
    min-height: 100vh;
}

.main .header {
    position: relative;
    margin-left: -0.32rem;
    margin-right: -0.32rem;
    padding: 0 0.32rem;
}

.main .header:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(81, 92, 109, .8);
    height: 4rem;
    width: 100%;
    z-index: 0;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}

.main .header .header-top {
    height: 1.22667rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}

.main .header .header-right {
    color: #fff;
    font-size: 0.333rem;
}

.main .header .header-right svg {
    height: 0.333rem;
    width: 0.333rem;
    position: relative;
    margin-left: 5px;
    top: 2px;
}

.main .header .logo img {
    max-height: 0.86rem;
    width: auto;
    margin-top: 0.2rem;
}

#txt--welcome{
    font-size: 0.5rem;
    color: #0d8da6;
    font-weight: bold;
  /* background: -webkit-linear-gradient(left, #396bb2, #ffdb2b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.main .swiper img {
    border-radius: 8px;
}

.main .list-game {
    display: grid;
    grid-gap: 0.3rem;
    margin: 0.3rem 0;
}

.main .box-game a {
    display: block;
    position: relative;
    width: 100%;
    height: 2.26667rem;
    text-align: end;
    border-radius: 0.26667rem;
    background: -webkit-linear-gradient( 325.68deg, #df2a2a 12.08%, #ee8d8d 85.02%);
    background: linear-gradient(124.32deg, #102d47 12.08%, #12304d 85.02%);
}

.main .box-game img {
    height: 100%;
    width: auto;
}

.main .box-game h3 {
    position: absolute;
    top: 0.26667rem;
    left: 0.4rem;
    color: #fff;
    white-space: break-spaces;
    font-weight: 700;
    font-size: 0.46667rem;
}

.main .box-game .box-game-text {
    color: #f4f5f8;
    font-weight: 400;
    font-size: 0.29333rem;
    position: absolute;
    bottom: 0.26667rem;
    left: 0.4rem;
    white-space: pre-wrap;
    text-align: left;
    line-height: 0.46667rem;
}

.main .box-game.op a {
    text-align: start;
    background: linear-gradient(-124.32deg, #df2a2a 12.08%, #ee8d8d 85.02%);
}

.main .box-game.op h3 {
    left: auto;
    right: 0.6em;
}

.main .box-game.op .box-game-text {
    left: auto;
    right: 1em;
    text-align: right;
}

.tab-navigation {
    height: 0.96rem;
    line-height: 0.96rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin: 0.8rem auto 0;
}

.tab-navigation>li {
    flex: 1;
    height: 100%;
    background: #e8e7e8;
    border-radius: 0.21333rem;
    font-size: 0.37333rem;
    color: #333;
    margin: 0 0.32rem;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
}

.tab-navigation>li.active {
    background: #0d8ea7;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    color: #fff;
}

.tab-content {
    font-size: 0.333rem;
    margin: 0.32rem;
    background: #fff;
    border-radius: 0.13333rem;
    overflow: hidden;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
}

table {
    width: 100%;
    border-collapse: collapse;
    color: #333;
}

table th,
table td {
    padding: 0.2rem;
}

table thead {
    background: #bfbfbf;
    color: #000;
    font-size: 0.4rem;
    font-weight: 500;
}

table tbody td {
    text-align: left;
}

table tbody td:last-child {
    text-align: right;
}

table tr {
    border-bottom: 1px solid #ebebeb;
}

table thead th {
    background-color: #f5f5f5;
    font-weight: bold;
}

table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

table tbody tr:hover {
    background-color: #ebebeb;
}

.award_tb {
    max-height: 10.3rem;
    overflow: auto;
}

.redball {
    background: linear-gradient(179deg, #13a2ba, #087c95);
    color: #fff;
    margin: 0 0.033rem;
    border-radius: 100%;
    width: 0.5rem;
    height: 0.5rem;
    line-height: 0.47rem;
    font-size: 0.33rem;
    text-align: center;
}

.ball_xs {
    display: flex;
}

.ball_xs .ball {
    background: #ffc200;
    color: #fff;
    margin: 0 0.04rem;
    border-radius: 100%;
    width: 0.7rem;
    height: 0.7rem;
    line-height: 0.65rem;
    font-size: 0.53rem;
    text-align: center;
}

.record_bet {
    min-height: 3.27333rem;
    background: #ffffff;
    border-radius: 0.53333rem;
    font-size: 0.444rem;
    color: #8b8b8b;
    position: relative;
    z-index: 1;
}

.colum-resultxs {
    display: flex;
    flex-flow: row wrap;
    padding: 0.3rem 0 0;
}

.colum-resultxs>.col-50 {
    width: 50%;
    padding: 0.1rem 0.3rem 0.1rem;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: center;
}

.colum-resultxs>.col-50 .info_bet {
    width: 100%;
    white-space: nowrap;
}

.colum-resultxs>.col-100 {
    width: 100%;
    padding: 0.3rem 0.5rem 0.5rem;
}

.colum-resultxs>div:first-child {
    border-right: 1px solid #eee;
}

.xs_before {
    margin: 0.2rem 0 0.3rem;
    color: #000;
    font-weight: 600;
}

.record_bet .count {
    height: 0.8rem;
    line-height: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3rem 0 0;
}

.record_bet .count>div {
    background: #efeff4;
    border-radius: 0.05333rem;
    color: #0d8ea7;
    font-weight: 700;
    font-size: 0.48rem;
    text-align: center;
    padding: 0 0.13333rem;
    border: 0.00667rem solid #fff;
}

.record_bet .count>.notime {
    background: transparent;
}

.btn-mini {
    padding: 0 0.15rem;
    height: 0.61333rem;
    font-size: 0.25333rem;
    text-align: center;
    border: .01333rem solid #13a2ba;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0d8ea7;
    background: #fff;
    margin: auto;
}

.btn-medium {
    padding: 0.1rem 0.3rem;
    height: 0.81333rem;
    font-size: 0.35333rem;
    text-align: center;
    background: linear-gradient(179deg, #13a2ba, #087c95);
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: none;
    margin: auto;
}

.box-quay {
    height: 3.09333rem;
    margin-top: 0.48rem;
    background: #00b977;
    border-radius: 0.18667rem;
    position: relative;
    padding: 0.26667rem;
}

.box-quay:after,
.box-quay:before {
    content: "";
    display: block;
    width: 0.13333rem;
    height: 0.69333rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
}

.box-quay:before {
    background: #008b59;
    border-radius: 0.13333rem 0 0 0.13333rem;
    left: -0.13333rem;
}

.box-quay:after {
    background: #008b59;
    border-radius: 0 0.13333rem 0.13333rem 0;
    right: -0.13333rem;
}

.box-quay .box {
    background: #003c26;
    border-radius: 0.13333rem;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.13333rem;
}

.box-quay .box:after,
.box-quay .box:before {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 0.18667rem solid transparent;
    border-bottom: 0.18667rem solid transparent;
    content: "";
    z-index: 3;
}

.box-quay .box:before {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-right: 0.53333rem solid transparent;
    border-left: 0.53333rem solid #00b977;
}

.box-quay .box:after {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-left: 0.53333rem solid transparent;
    border-right: 0.53333rem solid #00b977;
}

.box-quay .box .slot-column {
    display: inline-block;
    width: calc((100% - 0.53333rem) / 5);
    height: 100%;
    border-radius: 0.10667rem;
    overflow: hidden;
    background: #333;
    vertical-align: bottom;
    position: relative;
    text-align: center;
}

.box-quay .box .slot-column .slot-transform {
    transform: translateY(-2.73333rem);
}

.box-quay .box .slot-column .slot-transform .slot-num {
    width: 1.46667rem;
    height: 1.46667rem;
    line-height: 1.46667rem;
    background: #e1e1ec;
    border-radius: 50%;
    font-size: 0.8rem;
    color: #0006;
    font-weight: 700;
    margin: 0 auto 0.10667rem;
}

.box-quay .box .slot-column .slot-transform .slot-num:nth-child(3) {
    background: #00e065;
    color: #fff;
}

.box-quay .box .slot-column .slot-transform.slot-scroll {
    -webkit-animation: slotScroll 3s cubic-bezier(0.65, 0.02, 0.65, 1.06);
    animation: slotScroll 3s cubic-bezier(0.65, 0.02, 0.65, 1.06);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.box-quay .box .slot-column .slot-transform.slot-scroll[data-col="1"] {
    -webkit-animation-delay: 0.12s;
    animation-delay: 0.12s;
}

.box-quay .box .slot-column .slot-transform.slot-scroll[data-col="2"] {
    -webkit-animation-delay: 0.24s;
    animation-delay: 0.24s;
}

.box-quay .box .slot-column .slot-transform.slot-scroll[data-col="3"] {
    -webkit-animation-delay: 0.36s;
    animation-delay: 0.36s;
}

.box-quay .box .slot-column .slot-transform.slot-scroll[data-col="4"] {
    -webkit-animation-delay: 0.48s;
    animation-delay: 0.48s;
}

@-webkit-keyframes slotScroll {
    0% {
        -webkit-transform: translateY(-1.44rem);
        transform: translateY(-1.44rem);
    }
    to {
        -webkit-transform: translateY(-42.66667rem);
        transform: translateY(-29.33333rem);
    }
}

@keyframes slotScroll {
    0% {
        -webkit-transform: translateY(-1.44rem);
        transform: translateY(-1.44rem);
    }
    to {
        -webkit-transform: translateY(-42.66667rem);
        transform: translateY(-29.33333rem);
    }
}

.box-quay .box>[class^="num"] {
    width: calc((100% - 0.4rem) / 3);
    height: 2.29333rem;
    background-color: #333;
    border-radius: 0.13333rem;
    position: relative;
    background-position: center;
    background-size: 70%;
    background-repeat: no-repeat;
}

.box-quay .box>[class^="num"]:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 2;
    box-shadow: inset 0 -0.13333rem 0.13333rem #0000004d, inset 0 0.13333rem 0.13333rem #0000004d;
}

.box-quay .box>.num1 {
    background-image: url(./img/num1.png);
}

.box-quay .box>.num2 {
    background-image: url(./img/num2.png);
}

.box-quay .box>.num3 {
    background-image: url(./img/num3.png);
}

.box-quay .box>.num4 {
    background-image: url(./img/num4.png);
}

.box-quay .box>.num5 {
    background-image: url(./img/num5.png);
}

.box-quay .box>.num6 {
    background-image: url(./img/num6.png);
}

.text_choose_center {
    background: linear-gradient(124.32deg, #102d47 12.08%, #12304d 85.02%);
    box-shadow: 0 0.05333rem 0.10667rem #c5c5da40;
    border-radius: 0.26667rem;
    padding: 0.26667rem 0.34667rem;
    text-align: center;
    font-size: 0.34667rem;
    margin-top: 0.33rem;
}

.text_choose_center .bet_state {
    font-size: 0.3777rem;
    font-weight: bold;
    margin: 0.1rem 0 0.3rem;
    display: inline-block;
    border-bottom: 3px solid #0d8ea7;
}

.state_choose {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
}

.state_choose>div {
    flex: 1;
    margin: 0.1rem;
    border: 1px solid #e5e5e5;
    border-radius: 0.22rem;
    padding: 0.28rem;
    cursor: pointer;
}

.state_choose>div>* {
    display: block;
}

.state_choose>div>i {
    font-size: 0.35rem;
    color: #fff;
    margin-bottom: 0.2rem;
    font-weight: bold;
}

.state_choose .chooseItem {
    background: #0d8ea7;
    border: 1px solid #0d8ea7;
    color: #fff;
}

.state_choose .chooseItem * {
    color: #fff !important;
}

.state_choose .choose_xs {
    width: calc(10% - 0.1rem);
    padding: 0.1rem 0;
    margin: 0.05rem;
    flex: unset;
}

.popup-bet {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(81, 92, 109, 1);
    box-shadow: 0 0.05333rem 0.10667rem #c5c5da40;
    width: 100%;
    max-width: 540px;
    z-index: 101;
    padding: 0.3rem;
}

.main:has(.chooseItem) .popup-bet {
    display: block;
}

.main:has(.chooseItem) .footer {
    display: none;
}

.main:has(.tab-game) .footer {
    display: flex !important;
}

.item_choose_footer {
    display: flex;
    justify-content: space-between;
    font-size: 0.35rem;
}

.item_choose_footer .btn-sbmit {
    color: #fff;
    font-size: 0.3rem;
    font-weight: bold;
    text-shadow: none;
    border-radius: 1.06667rem;
    border: none;
    background: linear-gradient(179deg, #13a2ba, #087c95);
    box-shadow: none;
    padding: 0.1rem 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item_choose_footer input {
    padding: 0.16rem 0.14667rem;
    margin: 0 0 0 0.2rem;
    font-size: 0.27333rem;
    border: none;
    border-radius: 0.26667rem;
    box-shadow: 0 0.05333rem 0.21333rem #d0d0ed5c;
    border: 1px solid #e5e5e5;
}

.popup-backdrop {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 540px;
    height: 100vh;
    padding: 0.44rem;
    background: #000000c2;
    z-index: 9999;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.popup-main {
    border-radius: 0.44rem;
    overflow: hidden;
    background: #fff;
    width: 100%;
}

.popup-header {
    background: linear-gradient(179deg, #13a2ba, #087c95);
    color: #fff;
    font-size: 0.45rem;
    font-weight: bold;
    padding: 0.32rem;
}

.popup-content {
    color: #555;
    padding: 0.32rem;
    font-size: 0.35rem;
    text-align: left;
    line-height: 1.3;
}

.popup-close {
    color: #fff;
    font-size: 0.35rem;
    font-weight: bold;
    text-shadow: none;
    border-radius: 0.66667rem;
    border: none;
    background: linear-gradient(179deg, #13a2ba, #087c95);
    box-shadow: none;
    padding: 0.15rem 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.32rem auto;
}

.content-history {
    position: relative;
    color: #b7b7b7;
    font-size: 0.3rem;
}

.content-history .item_inner {
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
    border-bottom: 1px solid #e5e5e5;
}

.content-history .item_history {
    text-align: left;
}

.content-history .id_history_sanh {
    margin: 0.2rem 0 0.08rem;
}

.content-history .sanh {
    color: #fff;
    font-size: 0.36rem;
    font-weight: bold;
}

.content-history.award_tb .sanh,
.content-history.award_tb .money {
    color: #000
}

.content-history .money_history {
    text-align: right;
}

.content-history .money {
    color: #eee;
    display: block;
    margin: 0.2rem 0;
    font-size: 0.35rem;
}

.content-history .type_state {
    margin-left: 0.15rem;
    padding: 0.05rem 0.1rem;
    border-radius: 0.1rem;
    color: #fff;
}

.win {
    background-color: #6fe26f;
}

.lose {
    background-color: #b12424;
}

.pending {
    background-color: #ffc200;
}

.swal-button {
    background: #ffc200;
}

.swal-footer {
    text-align: center;
}

.swal-modal:has(.swal-icon--success) .swal-button {
    background: #a5dc86 !important;
}

.swal-modal:has(.swal-icon--error) .swal-button {
    background: #f27474 !important;
}

.swal-modal:has(.swal-icon--warning) .swal-button {
    background: #f8bb86 !important;
}

.swal-modal:has(.swal-icon--info) .swal-button {
    background: #3fc3ee !important;
}

.content_profile {
    font-size: 0.4rem;
}

.content_bank {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    color: #999696;
    margin: 1.7rem 0 0.5rem;
    font-size: 0.32rem;
}

.content_bank:not(:has(.item-banks:nth-child(2))) {
    font-size: 0.4rem;
}

.content_bank:not(:has(.item-banks:nth-child(2)))>div {
    width: 85%;
}

.content_bank>div {
    width: 85%;
    text-align: left;
    border: 1px solid #fff;
    padding: 0.15rem;
    position: relative;
}

.content_bank .copystk {
    position: absolute;
    right: 5px;
    font-size: 12px;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
    padding: 3px;
    border-radius: 3px;
    cursor: pointer;
}

/* .content_bank>div:nth-child(even) {
    border-left: 0;
} */

.content_bank b {
    color: #fff;
}

.huongdan ul li {
    list-style: none;
    margin-bottom: 0.2rem;
    text-align: left;
    position: relative;
    padding-left: 0.4rem;
    font-size: 0.35rem;
    color: #eee;
}

.huongdan ul li:before {
    position: absolute;
    content: "";
    width: 0.13333rem;
    height: 0.13333rem;
    background: #13a2ba;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: -0.06667rem;
    top: 0.15333rem;
}

.box-banking {
    background: url(./img/box-banking.png) no-repeat center center;
    background-size: 100% 100%;
    padding: 0.26667rem 0.34667rem;
    text-align: center;
    font-size: 0.34667rem;
    margin-top: 0.33rem;
    margin-left: -0.33rem;
    margin-right: -0.33rem;
    min-height: 5rem;
    position: relative;
}

.box-banking2 {
    background-image: url(./img/box-banking2.png);
    margin-left: 0;
    margin-right: 0;
}

.box-banking .icon_c#ffc200it {
    position: absolute;
    bottom: 0.9rem;
    left: 0.8rem;
    max-width: 0.9rem;
}

.box-banking .money_banking {
    position: absolute;
    top: 0.9rem;
    left: 0.8rem;
    color: #fff;
    font-size: 0.64rem;
    font-weight: bold;
    text-align: left;
}

.box-banking .money_banking h3 {
    font-weight: normal;
    font-size: 0.4rem;
    color: #f5f5f5;
}

.box-banking .ctk {
    position: absolute;
    bottom: 1.2rem;
    right: 1rem;
    font-size: 0.45rem;
    color: #f2f2f2;
}

.table-result {
    text-align: center;
    font-family: arial;
    width: 100%;
}

.table-result td {
    text-align: center !important;
}

.table-result th {
    border-right: 1px solid #ebebeb;
}

.table-result td,
.table-result th {
    vertical-align: middle;
}

.special-prize {
    float: left;
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 28px;
    font-weight: bold;
    color: #ed1c25;
}

.prize1 {
    float: left;
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}

.prize2 {
    float: left;
    width: 50%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}

.prize3 {
    float: left;
    width: 33.3%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}

.prize4 {
    float: left;
    width: 25%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}

.prize5 {
    float: left;
    width: 33.3%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}

.prize6,
.prize8 {
    float: left;
    width: 33.3%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}

.prize7 {
    float: left;
    width: 25%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-weight: bold;
    font-size: 0.48rem;
}

[class*="prize"]:empty {
    display: none;
}

.bacang {
    margin-bottom: 0.3rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #b7b7b7;
}

.tab-3cang {
    margin-left: -0.2rem;
    margin-right: -0.2rem;
    margin-top: 0.3rem;
}

.tab-3cang>li {
    margin: 0 0.05rem;
    font-size: 0.3rem;
    background: none;
    border: 1px solid #ffc107;
    line-height: 0.222rem;
    padding: 0.1333rem 0;
    color: #ffc107;
}

.tab-3cang>li.active {
    background: #ffc107;
    color: #fff;
}

.tab-3cang>li>div {
    width: 100%;
    height: 33.33%;
}

.tab-game>li {
    margin: 0 0.1rem;
    font-size: 0.28rem;
}

.box-image {
    box-shadow: 0 0 0.15rem 0 #d5c0c0;
    background: #fff;
    border-radius: 0.15rem;
    overflow: hidden;
    margin-bottom: 0.35rem;
}

.box-image-title {
    font-size: 0.3rem;
    padding: 0.1rem 0.2rem 0.3rem;
    color: #333;
}

.demo {
    position: fixed;
    width: 100%;
    max-width: 540px;
    height: 100vh;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    z-index: 99;
    background: #0000009d;
}

.loading {
    position: fixed;
    width: 100%;
    max-width: 540px;
    height: 100vh;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    z-index: 999;
    background: #0000009d;
}

body:has(.info_bet) .loading {
    display: none !important;
}

.loader {
    position: relative;
    overflow: hidden;
    display: block;
    height: 50px;
    width: 50px;
    margin: 0;
}

.loader:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 28px;
    height: 28px;
    margin: -16px 0 0 -16px;
    border: 2px solid #ffc200;
    border-top-color: #e1e8ed;
    border-right-color: #e1e8ed;
    border-radius: 100%;
    animation: spinner 1s infinite linear;
    transform-origin: center;
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

.route_game input {
    border: 1px solid #e5e5e5;
}

body:has(.admin-theme) .table-result th,
body:has(.admin-theme) .table-result td span,
body:has(.admin-theme) .table-result td {
    font-size: 20px !important;
    margin: 10px 0;
}

body:has(.admin-theme) .table-result td input {
    position: relative;
    top: -10px;
}

.button-admin {
    background: #ffc200 !important;
    color: #fff !important;
    margin: 5px !important;
}

body:has(.admin-theme) input {
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
    padding: 5px;
    font-size: 16px;
    max-width: 100%;
}

.col-1-1 {
    float: left;
    width: 50%;
    max-width: 250px;
    padding: 5px;
}

.form-admin {
    max-width: 400px;
    margin: 0 auto 15px;
    text-align: left;
}

.form-admin>div>div {
    margin: 15px 0;
    display: flex;
}

.form-admin label {
    width: 180px;
}

.form-admin button {
    margin: auto;
    display: block;
}

body:has(.admin-theme) h2 {
    margin: 15px 0 5px;
}

body:has(.admin-theme) #webpack-dev-server-client-overlay {
    display: none !important;
}

.promotionRule__container-content__rules-item {
    position: relative;
    padding: 0.57333rem 0.24rem 0.33333rem;
    border: 0.01333rem solid #dbb579;
    border-radius: 0.26667rem;
    border-top-right-radius: 0.26667rem;
    background: #fff;
    box-shadow: 0 0.05333rem 0.21333rem #d0d0ed5c;
}

.promotionRule__container-content__rules-item__splitBorder {
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    line-height: 0.1;
    text-align: center;
}

.promotionRule__container-content__rules-item__splitBorder>span {
    position: relative;
    top: -0.16rem;
    display: inline-block;
    width: 2.4rem;
    border-top: 0.02667rem solid #fff;
}

.promotionRule__container-content__rules-item__borderTopStyle {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    width: 100%;
}

.promotionRule__container-content__rules-item__borderTopStyle span {
    position: absolute;
    top: 0;
}

.promotionRule__container-content__rules-item__borderTopStyle span:first-of-type {
    left: -0.01333rem;
    width: 0.68rem;
    height: 0.68rem;
    border-top: 0.06667rem solid #dbb579;
    border-left: 0.06667rem solid #dbb579;
    border-top-left-radius: 0.26667rem;
}

.promotionRule__container-content__rules-item__borderTopStyle span:first-of-type:after {
    content: "";
    position: absolute;
    top: 0.29333rem;
    left: 0.2rem;
    width: 0.13333rem;
    height: 0.13333rem;
    border-radius: 50%;
    background-color: #dbb579;
}

.promotionRule__container-content__rules-item__borderTopStyle span:last-of-type {
    right: 0;
    width: 0.68rem;
    height: 0.68rem;
    border-top: 0.06667rem solid #dbb579;
    border-right: 0.06667rem solid #dbb579;
    border-top-right-radius: 0.26667rem;
}

.promotionRule__container-content__rules-item__borderTopStyle span:last-of-type:after {
    content: "";
    position: absolute;
    top: 0.29333rem;
    right: 0.2rem;
    width: 0.13333rem;
    height: 0.13333rem;
    border-radius: 50%;
    background-color: #dbb579;
}

.promotionRule__container-content__rules-item__titleLeft {
    position: absolute;
    top: -0.26667rem;
    left: calc(50% - 1.2rem);
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    width: 0.26667rem;
    height: 0.53333rem;
    background-color: #dbb579;
    -webkit-clip-path: polygon( 50% 0%, 100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%);
    clip-path: polygon(50% 0%, 100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%);
    z-index: 5;
}

.promotionRule__container-content__rules-item__title {
    position: absolute;
    top: -0.26667rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    width: 2.13333rem;
    height: 0.53333rem;
    color: #fff;
    font-size: 0.32rem;
    text-align: center;
    line-height: 0.53333rem;
    background-color: #dbb579;
    -webkit-clip-path: polygon( 7% 0%, 93% 0%, 100% 50%, 93% 100%, 7% 100%, 0% 50%);
    clip-path: polygon(7% 0%, 93% 0%, 100% 50%, 93% 100%, 7% 100%, 0% 50%);
}

.promotionRule__container-content__rules-item__titleRight {
    position: absolute;
    top: -0.26667rem;
    left: calc(50% + 1.2rem);
    -webkit-transform: translateX(-50%) rotate(180deg);
    transform: translate(-50%) rotate(180deg);
    width: 0.26667rem;
    height: 0.53333rem;
    background-color: #dbb579;
    -webkit-clip-path: polygon( 50% 0%, 100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%);
    clip-path: polygon(50% 0%, 100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%);
    z-index: 5;
}

.promotionRule__container-content__rules-item p {
    color: #666;
    font-size: 0.32rem;
    letter-spacing: 0.01333rem;
    line-height: 0.50667rem;
}

.countdown>div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.37333rem;
    color: #333;
    font-weight: 400;
}

.countdown>div>div {
    font-size: 0.53333rem;
    height: 0.8rem;
    border-radius: 0.13333rem;
    padding: 0 0.26667rem;
    line-height: 0.8rem;
    background: #efeff4;
    border-radius: 0.08rem;
    margin-left: 0.21333rem;
    color: #00b977;
    text-align: center;
}

.tkq {
    color: rgb(71, 123, 255);
    font-size: 0.4rem;
    font-weight: bold;
    display: block;
    margin-top: 0.2rem
}

.game-betting {
    margin: 0.8rem auto 0;
}

.game-betting .time-box {
    border-radius: 0.26667rem;
    background: #f94b55 url(./img/bannertimeout.webp) no-repeat 50%;
    background-size: auto 100%;
    display: flex;
    justify-content: space-between;
}

.game-betting .time-box .out {
    height: 100%;
    width: 50%;
    align-items: center;
    justify-content: center;
    border-left: 0.02667rem dashed #fff;
    position: relative;
    padding: 0.26667rem;
}

.game-betting .time-box .out:first-child {
    border: 0;
}

.game-betting .time-box .out:last-child:before {
    content: "";
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 0.4rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #f5f5f5;
    transform: translate(-50%, 60%);
}

.game-betting .time-box .out:last-child:after {
    content: "";
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 0.4rem;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f5f5f5;
    transform: translate(-50%, -60%);
}

.game-betting .time-box .out .txt {
    color: #fff;
    font-size: 0.37333rem;
}

.game-betting .time-box .out .number {
    margin-top: 0.13333rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.game-betting .time-box .out .number .item {
    padding: 0.053333rem;
    background-color: #da2031;
    font-size: 0.53333rem;
    color: #fff;
    font-weight: 600;
}

.game-betting .time-box .out .number .item:first-child {
    padding-left: 0.13333rem;
    background: linear-gradient(135deg, transparent 0.13333rem, #da2031 0) 0 0;
}

.game-betting .time-box .out .number .item:last-child {
    padding-right: 0.13333rem;
    background: linear-gradient(-45deg, transparent 0.13333rem, #da2031 0);
}

.btn-login {
    background: linear-gradient(124.32deg, #ffce1f 12.08%, #ccd26d 85.02%);
    border-radius: 10rem;
    color: #fff;
    font-size: 0.3rem;
    padding: 0.1rem 0.3rem;
    margin-right: 0.3rem;
}

.btn-register {
    background: linear-gradient(124.32deg, #50a1f2 12.08%, #85daff 85.02%);
    border-radius: 10rem;
    color: #fff;
    font-size: 0.3rem;
    padding: 0.1rem 0.3rem;
}

.tab-xucxac {
    height: 1.06667rem;
    background: #eeeeee;
    border-radius: 0.13333rem;
    display: flex;
    font-size: 0.29333rem;
    color: #7d7d7d;
    overflow: hidden;
    margin-bottom: 0.3rem;
}

.tab-xucxac>li {
    line-height: 1.06667rem;
    text-align: center;
    flex: 1;
}

.tab-xucxac>li.active {
    background: #ffc200;
    color: #fff;
    border-radius: 0.13333rem;
}

.state_xucxac>div:first-child {
    width: 100%;
    flex-basis: 100%;
}

.history_xucxac>div {
    width: 0.53333rem;
    height: 0.53333rem;
    background-repeat: no-repeat;
    background-size: 0.53333rem;
    background-position: center;
    margin: 0 0.05rem;
}

.history_xucxac>.n1 {
    background-image: url(./img/n1.png);
}

.history_xucxac>.n2 {
    background-image: url(./img/n2.png);
}

.history_xucxac>.n3 {
    background-image: url(./img/n3.png);
}

.history_xucxac>.n4 {
    background-image: url(./img/n4.png);
}

.history_xucxac>.n5 {
    background-image: url(./img/n5.png);
}

.history_xucxac>.n6 {
    background-image: url(./img/n6.png);
}

.main .box-game.op.xsmb>a {
    background: url(./img/mien-bac.png);
    background-size: cover;
}

.main .box-game.op.xsmt>a {
    background: url(./img/mien-trung.png);
    background-size: cover;
}

.main .box-game.op.xsmn>a {
    background: url(./img/mien-nam.png);
    background-size: cover;
}

.main .box-game.op.xsmb a>*,
.main .box-game.op.xsmt a>*,
.main .box-game.op.xsmn a>* {
    display: none;
}

.contentHtml {
    max-height: 300px;
    overflow-y: auto;
}

.btn-marquee {
    background: #ffc200;
    color: #fff;
    border: 0;
    border-radius: 0;
    padding: 5px 10px;
    cursor: pointer;
}

.marquees {
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: left;
    color: rgb(255, 194, 0);
    background: #0c192c;
    padding: 5px 15px;
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -5px;
    margin-bottom: -10px;
    overflow: hidden;
    width: calc(100% + 32px);
}

.marquees>div {
    line-height: 1;
    flex: 1;
}

.marquees>div:first-child {
    flex: 0 0 50px;
    text-align: left;
}

.marquee {
    width: calc(100% - 140px);
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;
}

.marquee>p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    animation: marquee 18s linear infinite;
    line-height: 1.3;
}

@keyframes marquee {
    0% {
        transform: translateX(35%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.quill {
    width: 100%;
    margin-bottom: 40px;
    min-height: 200px;
}

.logologin {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    width: 50%;
    max-width: 250px;
}

@media (max-width:1600px) {
    table {
        width: 100% !important
    }
}

.lsgd-table {
    display: flex;
    font-size: 0.32rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.lsgd-table>div {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
    text-align: left;
}

.table-lsgd td {
    padding: 0 0.1rem;
}

.table-result td span:empty {
    display: none;
}

.table-result td {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}

.choose_xs.promo {
    background: #62fd62;
}

.state_choose .chooseItem.promo {
    background: linear-gradient(#62fd62, #ff0000);
    border: 1px solid #62fd62;
}

#tab_xucxac10p .state_rowindex {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
}
#tab_xucxac10p .state_choose>div>i {
    margin-bottom: 0;
}
textarea#admin_widthraw_modal_edit{
    border: 0 !important;
    width: 100%;
}